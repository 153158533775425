import React from "react";
import {connect} from "react-redux";
import AdminHeader from "../../components/Headers/AdminHeader";
import {
    Card,
    CardHeader,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import ServerSideTable from "../../components/ServerSideTable";
import {List, Map} from "immutable";
import Moment from "react-moment";
import {showModalCreator} from "../../reducers/modalReducer";
import MessageModal from "./MessageModal";
import {DESCENDING} from "../../constants";

class Messages extends React.Component {

    constructor(props) {
        super(props);

        this.messagesTableName = 'messagesTableName';
        this.messageModalName = 'messageModalName';
    }

    render() {
        const {
            messages, showMessageModal
        } = this.props;

        return (
            <>
                <AdminHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 float-left">Nachrichten</h3>
                                </CardHeader>
                                <ServerSideTable columns={List([
                                    Map([
                                        ['key', 'createdAt'],
                                        ['display', 'Datum'],
                                        ['valueConverter', (val) => <Moment format={'DD.MM.YYYY HH:mm'}>{val}</Moment>]
                                    ]),
                                    Map([
                                        ['key', 'name'],
                                        ['display', 'Name']
                                    ]),
                                    Map([
                                        ['key', 'phone'],
                                        ['display', 'Telefonnummer']
                                    ]),
                                    Map([
                                        ['key', 'email'],
                                        ['display', 'E-Mail']
                                    ]),
                                   Map([
                                        ['key', ''],
                                        ['display', ''],
                                        ['sortable', false],
                                        ['valueConverter', (val, fullData) => <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="btn-icon-only text-light"
                                                href="#"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={e => e.preventDefault()}
                                            >
                                                <i className="fas fa-ellipsis-v"/>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showMessageModal(this.messageModalName, Map([['message', fullData.get('message')]]));
                                                    }}
                                                >
                                                    <i className="far fa-envelope"></i>
                                                    Nachricht anzeigen
                                                </DropdownItem>
                                                {/*<DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showSendCodeModal(this.sendCodeModalName, Map([['user', fullData]]))
                                                    }}
                                                >
                                                    <i className="far fa-envelope"></i>
                                                    E-Mail senden
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        copyActivationCodeToClipboard(window.location.origin + "/register/" + fullData.get('activationCode'));
                                                    }}
                                                >
                                                    <i className="far fa-clipboard"></i>
                                                    In die Zwischenablage kopieren
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        fullData.get('disabled') ? enableUser(fullData) : disableUser(fullData)
                                                    }}
                                                >
                                                    {fullData.get('disabled') ?
                                                        <><i className="fas fa-user-check"></i> Freischalten </> :
                                                        <><i className="fas fa-user-lock"></i> Sperren</>}
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showDeleteUserModal(this.deleteUserModalName, Map([['user', fullData]]))
                                                    }}
                                                >
                                                    <i className="far fa-trash-alt"></i>
                                                    Löschen
                                                </DropdownItem>*/}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>]
                                    ])])} values={messages} isLocal={true} tableName={this.messagesTableName}
                                                 textSearchProps={List(['name'])} sortOrder={DESCENDING}></ServerSideTable>
                            </Card>
                        </div>
                    </Row>
                    <MessageModal modalName={this.messageModalName}/>
                </Container>
            </>);
    }
}

export default connect(state => ({
        messages: state.sua.get('messages')
    }),
    dispatch => ({
        showMessageModal: (messageModalName, props) => dispatch(showModalCreator(messageModalName, props))
    })
)(Messages);