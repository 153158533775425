import React from "react";
import {Form, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DELETE_TABLE, DELETE_USER} from "../../actions";
import {connect} from "react-redux";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import QRCode from "qrcode.react";

class QrCodeTableModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    getQrCodeText() {
        const {user, table, basename} = this.props;

        let baseUrl = '';
        if (basename && basename !== '') {
            const basenameIdx = window.location.href.indexOf(basename);
            baseUrl = window.location.href.substring(0, basenameIdx + basename.length);
        } else {
            baseUrl = window.location.origin;
        }

        return encodeURI(`${baseUrl}/visit/${user ? user.get('businessKey') : ''}?table=${table ? table.get('name') : ''}`);
    }

    render() {
        const {table, isOpen, hideModal, backdrop} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>QR-Code für den Tisch {table ? table.get('name') : ''}</ModalHeader>
            <ModalBody>
                Der QR-Code für den Tisch: {table ? table.get('name') : ''}

                <div className="text-center mt-5">
                    <QRCode value={this.getQrCodeText()} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" onClick={hideModal}>Schließen</Button>
            </ModalFooter>
        </Modal>
    }
}

QrCodeTableModal = connect((state, ownProps) => ({
    table: state.modal.getIn([ownProps.modalName, 'table']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop']),
    user: state.sua.get('user'),
    basename: state.sua.get('basename')
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
}))(QrCodeTableModal);

export default QrCodeTableModal;