/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Form} from "reactstrap";
import {connect} from "react-redux";
import {endVisitCreator} from "../../actionCreators/generalActionCreators";

class VisitSuccess extends React.Component {

    render() {
        const {endVisit, visitId, visitEnded} = this.props;

        return <>
            <Col lg="6" md="8">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div className="text-center text-muted">
                            <h1>Bitte folgenden Button betätigen, sobald Sie das Lokal verlassen</h1>
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                        </div>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            endVisit(visitId);
                        }}>
                            <div className="text-center">
                                {visitEnded ? 'Sie haben das Lokal bereits verlassen. Wir freuen uns auf Ihren nächsten Besuch' :
                                    <Button className="mt-4" color="primary" type="submit">
                                        Ich verlasse das Lokal
                                    </Button>}
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    }
}

export default connect((state) => ({
    visitId: state.location.payload.visitId,
    tenantId: state.location.payload.tenantId,
    visitEnded: state.sua.get('visitEnded')
}), (dispatch) => ({
    endVisit: (visitId) => dispatch(endVisitCreator(visitId))
}))(VisitSuccess);
