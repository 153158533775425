import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class ImprintModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {isOpen, hideModal, backdrop} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Impressum</ModalHeader>
            <ModalBody>
                <div className="text-center mt-5">
                    <h3>Impressum:</h3>
                    <div>K3 Services e.U.</div>
                    <div>IT-Dienstleistungen</div>
                    <div>Inhaber/Owner: Christian Kloimüllner</div>
                    <div>3204 Kirchberg/Pielach</div>
                    <div>Telefon: +43 (0)680 / 2124655</div>
                    <div>E-Mail: office@k3-services.at</div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" onClick={hideModal}>Schließen</Button>
            </ModalFooter>
        </Modal>
    }
}

ImprintModal = connect((state, ownProps) => ({
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
}))(ImprintModal);

export default ImprintModal;