import React from "react";
import {connect} from "react-redux";
import AdminHeader from "../../components/Headers/AdminHeader";
import {
    Button,
    Card, CardFooter,
    CardHeader,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import ServerSideTable from "../../components/ServerSideTable";
import {List, Map} from "immutable";
import {showModalCreator} from "../../reducers/modalReducer";
import DeleteTableModal from "./DeleteTableModal";
import AddTableModal from "./AddTableModal";
import QrCodeTableModal from "./QrCodeTableModal";

class Tables extends React.Component {

    constructor(props) {
        super(props);

        this.tablesTableName = "tablesTable";
        this.deleteTableModalName = "deleteTableModal";
        this.addTableModalName = "addTableModal";
        this.qrCodeTableModalName = "qrCodeTableModal";
    }

    render() {
        const {
            tables, showQrCodeTableModal, showDeleteTableModal, showAddTableModal
        } = this.props;

        return (
            <>
                <AdminHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 float-left">Tischverwaltung</h3>
                                </CardHeader>
                                <ServerSideTable columns={List([
                                    Map([
                                        ['key', 'name'],
                                        ['display', 'Tischname']
                                    ]),
                                    Map([
                                        ['key', 'description'],
                                        ['display', 'Beschreibung']
                                    ]),
                                    Map([
                                        ['key', ''],
                                        ['display', ''],
                                        ['sortable', false],
                                        ['valueConverter', (val, fullData) => <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="btn-icon-only text-light"
                                                href="#"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={e => e.preventDefault()}
                                            >
                                                <i className="fas fa-ellipsis-v"/>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showQrCodeTableModal(this.qrCodeTableModalName, Map([['table', fullData]]));
                                                    }}
                                                >
                                                    QR-Code für diesen Tisch
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showDeleteTableModal(this.deleteTableModalName, Map([['table', fullData]]))
                                                    }}
                                                >
                                                    <i className="far fa-trash-alt"></i>
                                                    Löschen
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>]
                                    ])])} values={tables} isLocal={true} tableName={this.tablesTableName}
                                                 textSearchProps={List(['name'])}></ServerSideTable>
                                <CardFooter>
                                    <Button type='button' color='primary'
                                                        onClick={() => showAddTableModal(this.addTableModalName)}>Neuen Tisch
                                        hinzufügen</Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>

                    <AddTableModal modalName={this.addTableModalName} />
                    <DeleteTableModal modalName={this.deleteTableModalName} />
                    <QrCodeTableModal modalName={this.qrCodeTableModalName} />
                </Container>
            </>);
    }
}

export default connect(state => ({
        tables: state.sua.get('tables'),
        user: state.sua.get('user')
    }),
    dispatch => ({
        showDeleteTableModal: (showDeleteTableModalName, props) => dispatch(showModalCreator(showDeleteTableModalName, props)),
        showAddTableModal: (addTableModalName, props) => dispatch(showModalCreator(addTableModalName, props)),
        showQrCodeTableModal: (qrCodeTableModalName, props) => dispatch(showModalCreator(qrCodeTableModalName, props)),
    })
)(Tables);