import {NOT_FOUND} from 'redux-first-router'
import {
    COURSES_LIST,
    DASHBOARD, FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS,
    LOGIN_PAGE,
    MEMBERS_CREATE,
    MEMBERS_DETAILS,
    MEMBERS_LIST, MESSAGES_LIST,
    PAYMENT_TYPES_LIST,
    REGISTER, REGISTER_SUCCESS, TABLES,
    USERS_LIST, VISIT, VISIT_LIST
} from "../types";
import Auth from "../layouts/Auth";
import Admin from "../layouts/Admin";
import NotFound from "../views/NotFound";
import Dashboard from "../views/dashboard/Dashboard";
import {Map} from "immutable";
import Members from "../views/member/Members";
import Courses from "../views/course/Courses";
import PaymentTypes from "../views/PaymentTypes";
import Users from "../views/user/Users";
import MemberEdit from "../views/member/MemberEdit";
import Login from "../views/login/Login";
import ForgotPasswordSuccess from "../views/forgotPassword/ForgotPasswordSuccess";
import ForgotPassword from "../views/forgotPassword/ForgotPassword";
import Register from "../views/register/Register";
import RegisterSuccess from "../views/register/RegisterSuccess";
import Tables from "../views/tables/Tables";
import Visit from "../views/visit/Visit";
import {SAVE_VISIT_SUCCESS} from "../actions";
import VisitSuccess from "../views/visit/VisitSuccess";
import Visits from "../views/visit/Visits";
import Messages from "../views/message/Messages";

// Pagination URLs
const components = {
    [LOGIN_PAGE]: Login,
    [DASHBOARD]: Dashboard,
    [NOT_FOUND]: NotFound,
    [MEMBERS_LIST]: Members,
    [COURSES_LIST]: Courses,
    [PAYMENT_TYPES_LIST]: PaymentTypes,
    [USERS_LIST]: Users,
    [MEMBERS_CREATE]: MemberEdit,
    [MEMBERS_DETAILS]: MemberEdit,
    [FORGOT_PASSWORD_SUCCESS]: ForgotPasswordSuccess,
    [FORGOT_PASSWORD]: ForgotPassword,
    [REGISTER]: Register,
    [REGISTER_SUCCESS]: RegisterSuccess,
    [TABLES]: Tables,
    [VISIT]: Visit,
    [SAVE_VISIT_SUCCESS]: VisitSuccess,
    [VISIT_LIST]: Visits,
    [MESSAGES_LIST]: Messages
};

function pageReducer(state = Map({page: Auth, layout: Auth}), action) {
    const newPage = components[action.type] || state.get('page');
    const newLayout = newPage === LOGIN_PAGE || newPage === REGISTER ? Auth : Admin;

    return state.set('page', newPage).set('layout', newLayout);
}

export default pageReducer;