/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import ServerSideTable from "../../components/ServerSideTable";
// core components
import AdminHeader from '../../components/Headers/AdminHeader';
import {connect} from "react-redux";
import {restMap} from "../../routesMap";
import {VISIT_LIST} from "../../types";
import {List, Map} from "immutable";
import {showModalCreator} from "../../reducers/modalReducer";
import {changePageCreator, fetchDataThunk} from "../../reducers/tableReducer";
import ContactDetailsModal from "./ContactDetailsModal";
import Moment from "react-moment";

class Visits extends React.Component {

    constructor(props) {
        super(props);

        this.contactDetailsModalName = "contactDetailsModal";
        this.tableName = 'visits';
    }

    render() {
        const {
            showContactDetailsModal
        } = this.props;

        return (
            <>
                <AdminHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 float-left">Besuche</h3>

                                    <div className='float-right'>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ServerSideTable columns={List([
                                        Map([['key', 'lastname'], ['display', 'Nachname']]),
                                        Map([['key', 'firstname'], ['display', "Vorname"]]),
                                        Map([['key', 'beginOfVisit'], ['display', "Beginn"],
                                            ['valueConverter', (val) => <Moment format="DD.MM.YYYY HH:mm" locale="de">{val}</Moment>]
                                        ]),
                                        Map([
                                            ['key', 'endOfVisit'],
                                            ['display', "Ende"],
                                            ['valueConverter', (val) => <Moment format="DD.MM.YYYY HH:mm" locale="de">{val}</Moment>]
                                        ]),
                                        Map([
                                            ['key', 'table'],
                                            ['display', "Tischnummer"]
                                        ]),
                                        Map([
                                            ['key', 'actions'],
                                            ['display', "Aktionen"],
                                            ['sortable', false],
                                            ['valueConverter', (val, fullData) => <UncontrolledDropdown>
                                                <DropdownToggle
                                                    className="btn-icon-only text-light"
                                                    href="#"
                                                    role="button"
                                                    size="sm"
                                                    color=""
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="fas fa-ellipsis-v"/>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                    <DropdownItem
                                                        href="#"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            showContactDetailsModal(this.contactDetailsModalName, Map([['visitor', fullData]]));
                                                        }}
                                                    >
                                                        <i className="fas fa-pencil-alt"></i>
                                                        Kontaktdaten anzeigen
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            ]]),
                                    ])} api={restMap[VISIT_LIST]} tableName={this.tableName} sort={1}
                                                     disableReinit={true}>

                                    </ServerSideTable>
                                </CardBody>
                                <CardFooter>
                                    Einträge älter als 28 Tage werden automatisch vom System gelöscht.
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                    <ContactDetailsModal modalName={this.contactDetailsModalName}/>
                </Container>
            </>
        );
    }
}

export default connect(state => ({
        tableParams: state.table.getIn(['members', 'params'])
    }),
    (dispatch, ownProps) => ({
        showContactDetailsModal: (contactDetailsModalName, props) => dispatch(showModalCreator(contactDetailsModalName, props)),
        fetchTableData: (tableName) => dispatch(fetchDataThunk(tableName)),
        gotoFirstPage: (tableName) => dispatch(changePageCreator(tableName, 1)),
    }))(Visits);
