/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import {connect} from "react-redux";
import {Field, Form, Formik} from 'formik';
import {LOGIN} from "../../actions";
import {customInputForm} from "../../customComponents";

class Login extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {submit} = this.props;

        return (
            <>
                <Col lg="5" md="7">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-center">Verwaltung zur Nachverfolgung bei Covid-19-(Verdachts-)Fällen</div>
                            {/*<div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>*/}
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            {/*<div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>*/}
                            <Formik onSubmit={(values, {setSubmitting}) => {
                                submit(values, setSubmitting);
                            }} initialValues={{username: '', password: ''}}>
                                {({isSubmitting}) => (
                                    <Form>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-single-02"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Field placeholder="Benutzername" type="text" name="username" id='username'
                                                       component={customInputForm}/>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Field placeholder="Passwort" type="password" name="password" id='password'
                                                       component={customInputForm}/>
                                            </InputGroup>
                                        </FormGroup>

                                        {/*<div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>*/}
                                        <div className="text-center">
                                            <Button className="my-4" color="primary" type="submit" disabled={isSubmitting}>
                                                Anmelden
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                    <Row className="mt-3">
                        {/*<Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>*/}
                    </Row>
                </Col>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return ({
        submit: (loginData, setSubmitting) => {
            dispatch({type: LOGIN, username: loginData.username, password: loginData.password, setSubmitting: setSubmitting});
        }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

