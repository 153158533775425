import {all, call, put, take, select, takeEvery} from 'redux-saga/effects';
import {routesMap, sagasMap} from "./routesMap";
import {
    membersAddCourses,
    membersAddPayment,
    membersCreate,
    membersDelete,
    membersDeleteFromCurrentSeason,
    membersExist,
    membersToCurrentSeason,
    membersUndoPayment,
    membersUpdate
} from "./sagas/memberSaga";
import {courseCategoryCreate, coursesCreate, coursesDelete} from "./sagas/courseSaga";
import {seasonsAdd} from "./sagas/seasonSaga";
import {paymentTypesAdd, paymentTypesDelete} from "./sagas/paymentTypeSaga";
import {
    ADD_COURSES,
    ADD_MEMBER_TO_CURRENT_SEASON,
    ADD_PAYMENT,
    ADD_SEASON,
    APP_LOADED, CREATE_COURSE_CATEGORY,
    CREATE_MEMBER,
    CREATE_OR_UPDATE_COURSE,
    CREATE_OR_UPDATE_PAYMENT_TYPE, CREATE_OR_UPDATE_TABLE,
    DELETE_COURSE,
    DELETE_MEMBER,
    DELETE_MEMBER_FROM_CURRENT_SEASON,
    DELETE_PAYMENT_TYPE, DELETE_TABLE,
    DELETE_USER,
    DISABLE_USER,
    ENABLE_USER, END_VISIT, GET_VISIT, LOAD_ANON_TABLES,
    LOGIN, LOGIN_SUCCESS,
    MEMBER_EXISTS, REGISTER_NEW_TENANT, SAVE_VISIT,
    SEND_CODE, SET_PASSWORD,
    UNDO_PAYMENT,
    UPDATE_FAMILY_PAYMENTS,
    UPDATE_MEMBER,
    UPDATE_USER, USER_BY_ACTIVATION_CODE
} from "./actions";
import Api from "./api/Api";
import {
    fetchCoursesForCoachSaga,
    login,
    usersByActivationCode,
    usersDelete,
    usersDisable,
    usersEnable, usersEndVisit, usersRegister, usersSaveVisit,
    usersSendCode,
    usersUpdate
} from "./sagas/userSaga";
import {SUCCESS} from 'redux-clipboard-copy';
import {success} from "react-notification-system-redux";
import {dashboardUpdateFamilyPayments, fetchDashboardDataForSeason} from "./sagas/dashboardSaga";
import {LOGIN_PAGE, REGISTER} from "./types";
import {showLogin} from "./actionCreators/generalActionCreators";
import Register from "./views/forgotPassword/ForgotPassword";
import Login from "./views/login/Login";
import {FETCH_COURSES_FOR_COACH, FETCH_DASHBOARD_DATA} from "./api/apiCreators";
import {fetchAnonTables, tablesAdd, tablesDelete} from "./sagas/tableSaga";

function* getDataForRoute(action) {

    if (sagasMap[action.type]) {
        try {
            yield call(sagasMap[action.type], action);
        } catch (ex) {
            if (ex === 'jwt_invalid') {
                const state = yield select();

                if (state.page.get('page') !== REGISTER && state.page.get('page') !== LOGIN_PAGE) {
                    console.log('jwt_invalid ... redirecting to loginpage');

                    yield put(showLogin());
                }
            }
        }
    }
}

function* watchRoutes() {

    yield takeEvery(Object.keys(routesMap), getDataForRoute);

}

function* createCourseCategory() {
    yield takeEvery(CREATE_COURSE_CATEGORY, courseCategoryCreate);
}

function* createCourse() {
    yield takeEvery(CREATE_OR_UPDATE_COURSE, coursesCreate);
}

function* updateUser() {
    yield takeEvery(UPDATE_USER, usersUpdate);
}

function* existMember() {
    yield takeEvery(MEMBER_EXISTS, membersExist);
}

function* addSeason() {
    yield takeEvery(ADD_SEASON, seasonsAdd);
}

function* addMemberToCurrentSeason() {
    yield takeEvery(ADD_MEMBER_TO_CURRENT_SEASON, membersToCurrentSeason);
}

function* deleteMemberFromCurrentSeason() {
    yield takeEvery(DELETE_MEMBER_FROM_CURRENT_SEASON, membersDeleteFromCurrentSeason);
}

function* addPaymentType() {
    yield takeEvery(CREATE_OR_UPDATE_PAYMENT_TYPE, paymentTypesAdd);
}

function* addTable() {
    yield takeEvery(CREATE_OR_UPDATE_TABLE, tablesAdd);
}

export function* undoPayment() {
    yield takeEvery(UNDO_PAYMENT, membersUndoPayment);
}

function* addPayment() {
    yield takeEvery(ADD_PAYMENT, membersAddPayment);
}

function* deletePaymentType() {
    yield takeEvery(DELETE_PAYMENT_TYPE, paymentTypesDelete);
}

function* deleteTable() {
    yield takeEvery(DELETE_TABLE, tablesDelete);
}

function* deleteUser() {
    yield takeEvery(DELETE_USER, usersDelete);
}

function* disableUser() {
    yield takeEvery(DISABLE_USER, usersDisable);
}

function* enableUser() {
    yield takeEvery(ENABLE_USER, usersEnable);
}

function* updateMember() {
    yield takeEvery(UPDATE_MEMBER, membersUpdate);
}

function* createMember() {
    yield takeEvery(CREATE_MEMBER, membersCreate);
}

function* deleteMember() {
    yield takeEvery(DELETE_MEMBER, membersDelete);
}

function* sendCode() {
    yield takeEvery(SEND_CODE, usersSendCode);
}

function* watchClipboardCopies() {
    while (true) {
        yield take(SUCCESS);
        yield put(success({
            title: "Zwischenablage",
            message: "Der Aktivierungslink wurde erfolgreich in die Zwischenablage kopiert!"
        }));
    }
}

export function* saveVisit() {
    yield takeEvery(SAVE_VISIT, usersSaveVisit);
}

export function* endVisit() {
    yield takeEvery(END_VISIT, usersEndVisit);
}

export function* addCourses() {
    yield takeEvery(ADD_COURSES, membersAddCourses);
}

export function* deleteCourse() {
    yield takeEvery(DELETE_COURSE, coursesDelete);
}

export function* loginSuccess() {
    yield takeEvery(LOGIN_SUCCESS, initialSaga);
}

export function* userByActivationCode() {
    yield takeEvery(USER_BY_ACTIVATION_CODE, usersByActivationCode);
}

export function* register() {
    yield takeEvery(REGISTER_NEW_TENANT, usersRegister);
}

export function* initialSaga() {
    try {
        const {user} = yield all({
            user: call(Api.fetchCurrentUser)
        });
        yield put({type: APP_LOADED, user: user});
    } catch (ex) {
        console.log('initial saga failed', ex);
        if (ex === 'jwt_invalid') {
            const state = yield select();
            console.log(state);

            if (state.page.get('page') !== Register && state.page.get('page') !== Login) {

                yield put(showLogin());
            }
        }
    }
}

export function* updateFamilyPayments() {
    yield takeEvery(UPDATE_FAMILY_PAYMENTS, dashboardUpdateFamilyPayments);
}

export function* loginSaga() {
    yield takeEvery(LOGIN, login);
}

const addMemberToCurrentSeasonCreator = (tableName, memberId) => ({
    type: ADD_MEMBER_TO_CURRENT_SEASON,
    tableName: tableName,
    memberId: memberId
});
const deleteMemberFromCurrentSeasonCreator = (tableName, memberId) => ({
    type: DELETE_MEMBER_FROM_CURRENT_SEASON,
    tableName: tableName,
    memberId: memberId
});
export {
    addMemberToCurrentSeasonCreator,
    deleteMemberFromCurrentSeasonCreator
};

export function* fetchCoursesForCoach() {
    yield takeEvery(FETCH_COURSES_FOR_COACH, fetchCoursesForCoachSaga);
}

export function* fetchDashboardData() {
    yield takeEvery(FETCH_DASHBOARD_DATA, fetchDashboardDataForSeason);
}

export function* loadAnonTables() {
    yield takeEvery(LOAD_ANON_TABLES, fetchAnonTables);
}

export default function* rootSaga() {
    yield all([
        initialSaga(),
        loginSuccess(),
        watchRoutes(),
        createMember(),
        updateMember(),
        createCourse(),
        createCourseCategory(),
        deleteMember(),
        addCourses(),
        addPaymentType(),
        addPayment(),
        addMemberToCurrentSeason(),
        deleteMemberFromCurrentSeason(),
        addSeason(),
        deletePaymentType(),
        undoPayment(),
        deleteCourse(),
        deleteUser(),
        disableUser(),
        enableUser(),
        watchClipboardCopies(),
        sendCode(),
        updateUser(),
        existMember(),
        updateFamilyPayments(),
        loginSaga(),
        userByActivationCode(),
        register(),
        fetchCoursesForCoach(),
        fetchDashboardData(),
        addTable(),
        deleteTable(),
        saveVisit(),
        endVisit(),
        loadAnonTables()
    ]);


}