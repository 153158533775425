import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class MessageModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {isOpen, hideModal, backdrop, message} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Nachricht anzeigen</ModalHeader>
            <ModalBody>
                <pre>
                    {message}
                </pre>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" onClick={hideModal}>Schließen</Button>
            </ModalFooter>
        </Modal>
    }
}

MessageModal = connect((state, ownProps) => ({
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop']),
    message: state.modal.getIn([ownProps.modalName, 'message'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
}))(MessageModal);

export default MessageModal;