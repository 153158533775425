import {
    USER_BY_ACTIVATION_CODE,
    REGISTER_NEW_TENANT,
    ENABLE_USER,
    DISABLE_USER,
    SAVE_VISIT,
    END_VISIT, GET_VISIT, LOAD_ANON_TABLES, FETCH_MESSAGS_SUCCESS
} from "../actions";
import {LOGIN_PAGE} from "../types";

export const showLogin = () => ({type: LOGIN_PAGE}),
    getActivationCodeCreator = (activationCode) => ({type: USER_BY_ACTIVATION_CODE, activationCode: activationCode}),
    registerCreator = (data, setSubmitting) => ({
        type: REGISTER_NEW_TENANT,
        data: data,
        setSubmitting: setSubmitting
    }),
    enableUserCreator = (user) => ({
        type: ENABLE_USER,
        user: user
    }),
    disableUserCreator = (user) => ({
        type: DISABLE_USER,
        user: user
    }),
    saveVisitCreator = (data, tenantId, setSubmitting) => ({
       type: SAVE_VISIT,
       data: data,
        tenantId: tenantId,
        setSubmitting: setSubmitting
    }),
    endVisitCreator = (visitId) => ({
        type: END_VISIT,
        visitId: visitId
    }),
    loadAnonTablesCreator = (tenantId) => ({
        type: LOAD_ANON_TABLES,
        tenantId: tenantId
    }),
    fetchMessagesSuccessCreator = (messages) => ({
       type: FETCH_MESSAGS_SUCCESS,
        messages: messages
    });