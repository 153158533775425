import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class ContactDetailsModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    getQrCodeText() {
        const {user, table} = this.props;

        return `${window.location.origin}/visit/${user ? user.get('businessKey') : ''}?table=${table ? table.get('name') : ''}`;
    }

    getFullName() {
        const {visitor} = this.props;

        return visitor ? visitor.get('firstname') + ' ' + visitor.get('lastname') : '';
    }

    render() {
        const {visitor, isOpen, hideModal, backdrop} = this.props;

        return <>{visitor && <Modal isOpen={isOpen} backdrop={backdrop} size="lg">
            <ModalHeader toggle={hideModal}>Kontaktdaten für den Besucher {this.getFullName()}</ModalHeader>
            <ModalBody>
                Kontaktdaten für den Besucher {this.getFullName()}:

                {/*<div className="mt-5">
                    <span className="d-inline-block w-50 font-weight-bold">Adresse</span>
                    <span>{visitor.get('street')}</span>
                </div>
                <div>
                    <span className="d-inline-block w-50"/>
                    <span>{`${visitor.get('zipCode')} ${visitor.get('city')}`}</span>
                </div>
                <div>
                    <span className="d-inline-block w-50"/>
                    <span>{visitor.get('country')}</span>
                </div>*/}
                <div className="mt-2">
                    <span className="d-inline-block w-50 font-weight-bold">Telefonnummer</span>
                    <span><a href={"tel:" + visitor.get('phone')}>{visitor.get('phone')}</a></span>
                </div>
                <div className="mt-2">
                    <span className="d-inline-block w-50 font-weight-bold">E-Mail-Adresse</span>
                    <span><a href={"mailto:" + visitor.get('email')}>{visitor.get('email')}</a></span>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" onClick={hideModal}>Schließen</Button>
            </ModalFooter>
        </Modal>}</>
    }
}

ContactDetailsModal = connect((state, ownProps) => ({
    visitor: state.modal.getIn([ownProps.modalName, 'visitor']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
}))(ContactDetailsModal);

export default ContactDetailsModal;