import {call, put, select} from "redux-saga/effects";
import Api from "../api/Api";
import {
    CREATED, DELETE_PAYMENT_TYPE_FAILED, DELETE_PAYMENT_TYPE_SUCCESS, DELETED, FAILED,
    LIST_TABLES_COMPLETE
} from "../actions";
import {error, success} from "react-notification-system-redux";
import {hideModalCreator} from "../reducers/modalReducer";

export function* tablesList() {
    const state = yield select();

    console.log(state.sua.getIn(['user', 'businessKey']));

    const tables = yield call(Api.fetchTables, state.sua.getIn(['user', 'businessKey']));

    yield put({
        type: LIST_TABLES_COMPLETE,
        tables: tables
    });
}

export function* fetchAnonTables(action) {
    const {tenantId} = action;

    const tables = yield call(Api.fetchTables, tenantId, true);

    yield put({
        type: LIST_TABLES_COMPLETE,
        tables: tables
    });
}

export function* tablesAdd(action) {
    const {table, user, resetForm, setSubmitting} = action,
        isEdit = !!table.get('businessKey');

    try {
        if (isEdit) {
            yield call(Api.updateTable, table, user);
        } else {
            yield call(Api.addTable, table, user);
        }
        const tables = yield call(Api.fetchTables, user.get('businessKey'));

        setSubmitting(false);

        yield put({
            type: LIST_TABLES_COMPLETE,
            tables: tables
        });

        yield put({type: CREATED});

        yield put(success({
            title: isEdit ? "Tisch aktualisiert" : `Neuer Tisch "${table.get('name')}" hinzugefügt`,
            message: isEdit ? `Der Tisch ${table.get('name')} wurde erfolgreich aktualisiert` :
                `Der Tisch ${table.get('name')} wurde erfolgreich hinzugefügt.`
        }));

        if (!isEdit) {
            resetForm({});
        } else {
            yield put(hideModalCreator(action.modalName));
        }

    } catch (tableError) {
        yield put({type: FAILED});

        yield put(error({
            title: 'Fehler aufgetreten',
            message: 'Beim Hinzufügen eines neuen Tisches ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        }));
    }
}

export function* tablesDelete(action) {
    const {table, modalName, user} = action;

    try {
        yield call(Api.deleteTable, table);

        const tables = yield call(Api.fetchTables, user.get('businessKey'));

        yield put({
            type: LIST_TABLES_COMPLETE,
            tables: tables
        })

        yield put(success({
            title: 'Tisch erfolgreich gelöscht',
            message: `Der Tisch "${table.get('name')}" wurde erfolgreich gelöscht.`
        }));

        yield put({type: DELETED});

        yield put(hideModalCreator(modalName));
    } catch (deletePaymentTypeError) {
        yield put({type: FAILED});

        yield put(error({
            title: 'Fehler aufgetreten',
            message: 'Beim Löschen einer Beitragsgruppe ist ein unerwarteter Fehler aufgetreten.',
        }));

    }
}