const SEX = {
    MALE: {
        value: "MALE",
        label: "männlich"
    },
    FEMALE: {
        value: "FEMALE",
        label: "weiblich"
    }
}, ROLES = {
    ADMIN: "ADMIN",
    COACH: "COACH",
    MEMBER: "MEMBER",
    TENANT_ADMIN: "TENANT_ADMIN"
}

export {SEX, ROLES};