import Api from "../api/Api";
import {call, put} from "redux-saga/es/effects";
import {CTX} from "../constants";
import {
    DISABLED_USER,
    ENABLED_USER,
    HIDE_MODAL,
    LIST_USERS_COMPLETE,
    SENT_CODE_SUCCESS,
    UPDATE_USER_FAILED,
    UPDATE_USER_SUCCESS,
    USER_DELETED,
    LOGIN_SUCCESS,
    CREATED_COURSE_SUCCESS,
    USER_BY_ACTIVATION_CODE,
    USER_BY_ACTIVATION_CODE_SUCCESS,
    SAVE_VISIT_SUCCESS,
    VISIT_ENDED
} from "../actions";
import {DELETE_USER_MODAL, EDIT_USER_MODAL, SEND_CODE_MODAL} from "../modals";
import {error, success} from "react-notification-system-redux";
import {DASHBOARD, REGISTER_SUCCESS} from "../types";
import {hideModalCreator} from "../reducers/modalReducer";
import {redirect} from "redux-first-router";
import {fetchCoursesForCoachSuccessCreator} from "../api/apiCreators";
import {fetchMessagesSuccessCreator} from "../actionCreators/generalActionCreators";

export function* usersUpdate(action) {
    try {
        const newUsername = yield call(Api.updateUser, action.user);
        action.user.username = newUsername;
        const users = yield call(Api.fetchUsers);
        yield put({type: UPDATE_USER_SUCCESS, users: users});
        yield put(success({
            title: "Editieren des Benutzers erfolgreich",
            message: "Das Editieren des Benutzers " + action.user.firstname + " " + action.user.lastname + " war erfolgreich!"
        }));
    } catch (updateUserError) {
        yield put(error({
            title: "Editieren des Benutzers fehlgeschlagen",
            message: "Das Editieren des Benutzers ist leider fehlgeschlagen. Bitte versuchen Sie es später erneut!"
        }));
        yield put({type: UPDATE_USER_FAILED, user: action.user});
    }
    yield put(hideModalCreator(EDIT_USER_MODAL));
}

export function* login(action) {
    try {
        const loginResponse = yield call(Api.login, action.username, action.password);
        yield put({type: LOGIN_SUCCESS, loginData: loginResponse});
        yield put({type: DASHBOARD});
    } catch (loginError) {
        yield put(error({
            title: "Anmeldung fehlgeschlagen",
            message: "Benutzername und/oder Passwort falsch"
        }));
        action.setSubmitting(false);
    }
}

export function* usersList(action) {
    const users = yield call(Api.fetchUsers);
    console.log("in generator function", users);
    yield put({type: LIST_USERS_COMPLETE, users: users});
}

export function* loginInit(action) {
}

export function* usersDelete(action) {
    const {user} = action;

    yield call(Api.deleteUser, user.get('businessKey'));

    const users = yield call(Api.fetchUsers);
    yield put({type: UPDATE_USER_SUCCESS, users: users});

    yield put(success({
        title: 'Benutzer erfolgreich gelöscht',
        message: `Der Benutzer ${user.get('firstname')} ${user.get('lastname')} wurde erfolgreich gelöscht!`
    }));

    yield put(hideModalCreator(action.modalName));
}

export function* usersDisable(action) {
    yield call(Api.disableUser, action.user.get('businessKey'));

    const users = yield call(Api.fetchUsers);
    yield put({type: UPDATE_USER_SUCCESS, users: users});

    yield put(success({
        title: 'Benutzer erfolgreich gesperrt',
        message: `Der Benutzer ${action.user.get('firstname')} ${action.user.get('lastname')} wurde erfolgreich gesperrt!`
    }));
}

export function* usersEnable(action) {
    yield call(Api.enableUser, action.user.get('businessKey'));

    const users = yield call(Api.fetchUsers);
    yield put({type: UPDATE_USER_SUCCESS, users: users});

    yield put(success({
        title: 'Benutzer erfolgreich freigeschalten',
        message: `Der Benutzer ${action.user.get('firstname')} ${action.user.get('lastname')} wurde erfolgreich freigeschalten!`
    }));
}

export function* usersSendCode(action) {
    const {user, email, modalName} = action;

    yield call(Api.sendCode, user.get('firstname'), `${window.location.origin}/register/${user.get('activationCode')}`, email, user.get('sex'));
    yield put({type: SENT_CODE_SUCCESS});
    yield put(hideModalCreator(modalName));
    yield put(success({
        title: "Email erfolgreich versendet",
        message: `Die E-Mail mit dem Aktivierungslink wurde erfolgreich an den Kursleiter ${user.get('firstname')} ${user.get('lastname')} versendet!`
    }));
}

export function* usersByActivationCode(action) {
    try {
        const user = yield call(Api.getUserByActivationCode, action.activationCode);

        yield put({type: USER_BY_ACTIVATION_CODE_SUCCESS, user: user});
    } catch (ex) {
        yield put(error({
            title: 'Ungültiger Aktivierungscode',
            message: 'Der eingegebene Aktivierungscode ist ungültig. Sie wurden auf die Loginseite umgeleitet.'
        }))
    }
}

export function* usersSaveVisit(action) {
    const {data, tenantId, setSubmitting} = action;

    try {
        const visitId = yield call(Api.saveVisit, data, tenantId);

        yield put(success({
            title: 'Daten erfolgreich abgeschickt',
            message: 'Die Daten wurden erfolgreich abgeschickt.'
        }));
        yield put(redirect({type: SAVE_VISIT_SUCCESS, payload: {tenantId: tenantId, visitId: visitId}}));
    } catch(ex) {
        yield put(error({
            title: 'Fehler',
            message: 'Die Daten konnten nicht erfolgreich abgeschickt werden.'
        }));
    }

    setSubmitting(false);
}

export function* usersEndVisit(action) {
    const {visitId} = action;

    try {
        yield call(Api.endVisit, visitId);

        yield put(success({
            title: 'Sie haben das Lokal verlassen',
            message: 'Sie haben das Lokal verlassen. Verwwenden Sie beim näcshten Bewsuch gerne wieder diesen QR-Code.'
        }));

        yield put({type: VISIT_ENDED});
    } catch(ex) {
        yield put(error({
            title: 'Fehler',
            message: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
        }));
    }
}

export function* usersRegister(action) {
    const {data, setSubmitting} = action;

    try {
        yield call(Api.registerUser, data);

        yield put(success({
            title: 'Registrierung erfolreich',
            message: 'Nach Aktivierung Ihres Benutzerkontos können Sie das System verwenden.'
        }));
        yield put(redirect({type: REGISTER_SUCCESS}));
    } catch (ex) {
        yield put(error({
            title: 'Registrierung fehlgeschlagen',
            message: 'Es ist ein Fehler bei der Registrierung aufgetreten. Sollte dieses Problem öfters auftreten, ' +
                'kontaktieren Sie bitte den Support unter office@k3-services.at.'
        }));
    }

    setSubmitting(false);
}

export function* fetchCoursesForCoachSaga(action) {
    try {
        const courses = yield call(Api.fetchCoursesForCoach, action.id);

        yield put(fetchCoursesForCoachSuccessCreator(courses, action.id));
    } catch (ex) {
        console.log('error while querying courses for coach with id=[', action.id, ']', ex);
    }
}

export function* messagesList(action) {
    try {
        const messages = yield call(Api.fetchMessages);

        yield put(fetchMessagesSuccessCreator(messages));
    } catch (ex) {

    }
}