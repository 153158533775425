import React from "react";
import {connect} from "react-redux";
import {
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {Map} from "immutable";
import {object, string} from "yup";
import {CREATE_OR_UPDATE_TABLE} from "../../actions";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import {customInputForm} from "../../customComponents";

class AddTableModal extends React.Component {

    constructor(props) {
        super(props);

        this.TableSchema = object().shape({
            name: string().required('Pflichtfeld'),
            description: string()
        });

        const {initModal} = this.props;

        initModal();
    }

    isEdit() {
        const {tableEdit} = this.props;

        return tableEdit && tableEdit.get('businessKey');
    }

    checkTable(prop, defaultValue) {
        const {tableEdit} = this.props;

        return this.isEdit() ? tableEdit.get(prop) : (defaultValue ? defaultValue : '');
    }

    render() {
        const {tableEdit, isOpen, backdrop, hideModal, createOrUpdate, user} = this.props,
            title = this.isEdit() ? "Tisch bearbeiten" : "Neuen Tisch hinzufügen",
            desc = this.isEdit() ? "Bitte ändern Sie hier die gewünschten Daten des Tisches" : "Bitte geben Sie hier die gewünschten Daten des neuen Tisches ein",
            successBtnTxt = this.isEdit() ? "Speichern" : "Hinzufügen";

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>
                {title}
            </ModalHeader>
            <Formik enableReinitialize
                    initialValues={{
                        name: this.checkTable('name'),
                        description: this.checkTable('description')
                    }}
                    validationSchema={this.TableSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        createOrUpdate(tableEdit.merge({
                            name: values.name,
                            description: values.description
                        }), user, setSubmitting, resetForm);
                    }}>
                {({isSubmitting}) => (
                    <Form>
                        <ModalBody>
                            <h3 className='mb-2'>
                                {desc}
                            </h3>
                            <fieldset id="add-payment-type-modal-fieldset">
                                <FormGroup>
                                    <Label for="name">Tischname</Label>
                                    <Field name="name" className="form-control" type="text"
                                           placeholder="Tischname..." component={customInputForm}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Beschreibung</Label>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-align-left-2"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Field id="description" name="description" rows="5" type="textarea"
                                               placeholder="Beschreibung..." component={customInputForm}/>
                                    </InputGroup>
                                </FormGroup>
                            </fieldset>
                        </ModalBody>
                        <ModalFooter>
                            <Button type='button' color='secondary' onClick={() => hideModal()}>Abbrechen</Button>
                            <Button type='submit' color='primary' disabled={isSubmitting}>{successBtnTxt}</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    }
}

export default connect((state, ownProps) => ({
    tableEdit: state.modal.getIn([ownProps.modalName, 'tableEdit']) || Map(),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop']),
    user: state.sua.get('user')
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    createOrUpdate: (table, user, setSubmitting, resetForm) => (dispatch({
        type: CREATE_OR_UPDATE_TABLE,
        table: table,
        user: user,
        modalName: ownProps.modalName,
        setSubmitting: setSubmitting,
        resetForm: resetForm
    }))
}))(AddTableModal);