const
    CREATE_MEMBER = "CREATE_MEMBER",
    UPDATE_MEMBER = "UPDATE_MEMBER",
    UPDATED_MEMBER = "UPDATED_MEMBER",
    UPDATE_MEMBER_FAILED = "UPDATE_MEMBER_FAILED",
    LIST_MEMBERS_COMPLETE = "LIST_MEMBERS_COMPLETE",
    LIST_COURSES_COMPLETE = "LIST_COURSES_COMPLETE",
    CREATE_MEMBER_FAILED = "CREATE_MEMBER_FAILED",
    CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS",
    DELETE_MEMBER = "DELETE_MEMBER",
    ADD_PAYMENT = "ADD_PAYMENT",
    ADDED_PAYMENT = "ADDED_PAYMENT",
    UNDONE_PAYMENT = "UNDONE_PAYMENT",
    UNDO_PAYMENT = "UNDO_PAYMENT",
    SHOW_MODAL = "SHOW_MODAL",
    LOGOUT = "LOGOUT",
    HIDE_MODAL = "HIDE_MODAL",
    ADD_COURSES = "ADD_COURSES",
    CREATED_COURSE_SUCCESS = "CREATED_COURSE_SUCCESS",
    DELETED_COURSE = "DELETED_COURSE",
    APP_LOADED = "APP_LOADED",
    MEMBER_SEARCH = "MEMBER_SEARCH",
    CHANGE_SEASON = "CHANGE_SEASON",
    ADD_SEASON = "ADD_SEASON",
    ADDED_SEASON = "ADDED_SEASON",
    ADD_MEMBER_TO_CURRENT_SEASON = "ADD_MEMBER_TO_CURRENT_SEASON",
    ADD_MEMBER_TO_CURRENT_SEASON_SUCCESS = "ADD_MEMBER_TO_CURRENT_SEASON_SUCCESS",
    DELETE_MEMBER_FROM_CURRENT_SEASON = "DELETE_MEMBER_FROM_CURRENT_SEASON",
    LIST_PAYMENT_TYPES_COMPLETE = "LIST_PAYMENT_TYPES_COMPLETE",
    CREATE_OR_UPDATE_PAYMENT_TYPE = "CREATE_OR_UPDATE_PAYMENT_TYPE",
    CREATE_OR_UPDATE_COURSE = "CREATE_OR_UPDATE_COURSE",
    DELETE_PAYMENT_TYPE = "DELETE_PAYMENT_TYPE",
    DELETE_COURSE = "DELETE_COURSE",
    CREATED_PAYMENT_TYPE_FAILED = "CREATED_PAYMENT_TYPE_FAILED",
    CREATED_PAYMENT_TYPE_SUCCESS = "CREATED_PAYMENT_TYPE_SUCCESS",
    EDIT_PAYMENT_TYPE_SUCCESS = "EDIT_PAYMENT_TYPE_SUCCESS",
    DELETE_PAYMENT_TYPE_SUCCESS = "DELETE_PAYMENT_TYPE_SUCCESS",
    DELETE_PAYMENT_TYPE_FAILED = "DELETE_PAYMENT_TYPE_FAILED",
    DASHBOARD_DATA_COMPLETE = "DASHBOARD_DATA_COMPLETE",
    LIST_USERS_COMPLETE = "LIST_USERS_COMPLETE",
    DELETE_USER = "DELETE_USER",
    USER_DELETED = "USER_DELETED",
    DISABLE_USER = "DISABLE_USER",
    ENABLE_USER = "ENABLE_USER",
    DISABLED_USER = "DISABLED_USER",
    ENABLED_USER = "ENABLED_USER",
    ADD_COURSE_MODAL_COACH_CHANGED = "ADD_COURSE_MODAL_COACH_CHANGED",
    TOGGLE_NEW_COACH_CHECKBOX = "TOGGLE_NEW_COACH_CHECKBOX",
    SEND_CODE = "SEND_CODE",
    SENT_CODE_SUCCESS = "SENT_CODE_SUCCESS",
    MEMBERCREATEFORM_SEX_CHANGED = "MEMBERCREATEFORM_SEX_CHANGED",
    MEMBERCREATEFORM_SET_PAYMENTTYPE_MULTIPLE = "MEMBERCREATEFORM_SET_PAYMENTTYPE_MULTIPLE",
    MEMBERCREATEFORM_RELATIVES_CHANGED = "MEMBERCREATEFORM_RELATIVES_CHANGED",
    MEMBERCREATEFORM_COURSES_CHANGED = "MEMBERCREATEFORM_COURSES_CHANGED",
    UPDATE_USER = "UPDATE_USER",
    EDIT_USER_SEX_CHANGED = "EDIT_USER_SEX_CHANGED",
    UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILED = "UPDATE_USER_FAILED",
    MEMBER_EXISTS = "MEMBER_EXISTS",
    UPDATE_FAMILY_PAYMENTS = "UPDATE_FAMILY_PAYMENTS",
    UPDATED_FAMILY_PAYMENTS = "UPDATED_FAMILY_PAYMENTS",
    CREATE_COURSE_FAILED = "CREATE_COURSE_FAILED",
    LOGIN = "LOGIN",
    REFRESH_TOKEN = "REFRESH_TOKEN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    FETCH_MEMBER_DETAIL_COMPLETE = "FETCH_MEMBER_DETAIL_COMPLETE",
    CREATE_COURSE_CATEGORY = "CREATE_COURSE_CATEGORY",
    CREATE_COURSE_CATEGORY_SUCCESS = "CREATE_COURSE_CATEGORY_SUCCESS",
    CREATE_COURSE_CATEGORY_FAILED = "CREATE_COURSE_CATEGORY_FAILED",
    USER_BY_ACTIVATION_CODE = 'USER_BY_ACTIVATION_CODE',
    USER_BY_ACTIVATION_CODE_SUCCESS = 'USER_BY_ACTIVATION_CODE_SUCCESS',
    SET_PASSWORD = 'SET_PASSWORD',
    REGISTER_NEW_TENANT = 'REGISTER_NEW_TENANT',
    DELETE_TABLE = "DELETE_TABLE",
    LIST_TABLES_COMPLETE = "LIST_TABLES_COMPLETE",
    CREATE_OR_UPDATE_TABLE = "CREATE_OR_UPDATE_TABLE",
    CREATED = "CREATED_",
    FAILED = "FAILED",
    DELETED = "DELETED",
    SAVE_VISIT = "SAVE_VISIT",
    SAVE_VISIT_SUCCESS = "SAVE_VISIT_SUCCESS",
    END_VISIT = "END_VISIT",
    VISIT_ENDED = "VISIT_ENDED",
    LOAD_ANON_TABLES = "LOAD_ANON_TABLES",
    FETCH_MESSAGS_SUCCESS = "FETCH_MESSAGS_SUCCESS";

export {
    CREATE_MEMBER,
    UPDATE_MEMBER,
    UPDATED_MEMBER,
    UPDATE_MEMBER_FAILED,
    LIST_MEMBERS_COMPLETE,
    LIST_COURSES_COMPLETE,
    CREATE_MEMBER_FAILED,
    DELETE_MEMBER,
    SHOW_MODAL,
    HIDE_MODAL,
    ADD_COURSES,
    CREATED_COURSE_SUCCESS,
    APP_LOADED,
    CHANGE_SEASON,
    ADD_SEASON,
    ADDED_SEASON,
    ADD_MEMBER_TO_CURRENT_SEASON,
    ADD_MEMBER_TO_CURRENT_SEASON_SUCCESS,
    DELETE_MEMBER_FROM_CURRENT_SEASON,
    LIST_PAYMENT_TYPES_COMPLETE,
    CREATE_OR_UPDATE_PAYMENT_TYPE,
    CREATE_OR_UPDATE_COURSE,
    CREATED_PAYMENT_TYPE_FAILED,
    CREATED_PAYMENT_TYPE_SUCCESS,
    EDIT_PAYMENT_TYPE_SUCCESS,
    DELETE_PAYMENT_TYPE,
    DELETE_PAYMENT_TYPE_SUCCESS,
    DELETE_PAYMENT_TYPE_FAILED,
    ADD_PAYMENT,
    ADDED_PAYMENT,
    UNDO_PAYMENT,
    UNDONE_PAYMENT,
    DELETE_COURSE,
    DELETED_COURSE,
    DASHBOARD_DATA_COMPLETE,
    LOGOUT,
    LIST_USERS_COMPLETE,
    DELETE_USER,
    USER_DELETED,
    DISABLE_USER,
    ENABLE_USER,
    DISABLED_USER,
    ENABLED_USER,
    ADD_COURSE_MODAL_COACH_CHANGED,
    TOGGLE_NEW_COACH_CHECKBOX,
    SEND_CODE,
    SENT_CODE_SUCCESS,
    MEMBERCREATEFORM_SEX_CHANGED,
    MEMBERCREATEFORM_SET_PAYMENTTYPE_MULTIPLE,
    MEMBERCREATEFORM_RELATIVES_CHANGED,
    MEMBERCREATEFORM_COURSES_CHANGED,
    UPDATE_USER,
    EDIT_USER_SEX_CHANGED,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    MEMBER_EXISTS,
    UPDATE_FAMILY_PAYMENTS,
    UPDATED_FAMILY_PAYMENTS,
    CREATE_COURSE_FAILED,
    LOGIN,
    REFRESH_TOKEN,
    LOGIN_SUCCESS,
    MEMBER_SEARCH,
    CREATE_MEMBER_SUCCESS,
    FETCH_MEMBER_DETAIL_COMPLETE,
    CREATE_COURSE_CATEGORY,
    CREATE_COURSE_CATEGORY_SUCCESS,
    CREATE_COURSE_CATEGORY_FAILED,
    USER_BY_ACTIVATION_CODE,
    USER_BY_ACTIVATION_CODE_SUCCESS,
    SET_PASSWORD,
    REGISTER_NEW_TENANT,
    DELETE_TABLE,
    LIST_TABLES_COMPLETE,
    CREATE_OR_UPDATE_TABLE,
    CREATED,
    FAILED,
    DELETED,
    SAVE_VISIT,
    SAVE_VISIT_SUCCESS,
    END_VISIT,
    VISIT_ENDED,
    LOAD_ANON_TABLES,
    FETCH_MESSAGS_SUCCESS
};