import {
    COURSES_CREATE,
    COURSES_LIST,
    DASHBOARD,
    DASHBOARD2,
    MEMBERS_CREATE,
    MEMBERS_DELETE,
    MEMBERS_DETAILS,
    MEMBERS_FILTER,
    MEMBERS_FOR_SEASON,
    MEMBERS_LIST,
    PAYMENT_TYPES_LIST,
    SEASONS_ADD,
    SEASONS_LIST,
    USERS_LIST,
    LOGIN_PAGE,
    COACH_LIST,
    COURSE_CATEGORIES,
    REGISTER,
    REGISTER_SUCCESS,
    COURSES_LIST_OWNER,
    CURRENT_USER,
    COURSES_LIST_OWNER_ID, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD, TABLES, VISIT, VISIT_LIST, MESSAGES_LIST
} from "./types";
import {loginInit, messagesList} from "./sagas/userSaga";
import {membersDetails, membersList} from "./sagas/memberSaga";
import {coursesCreate, coursesList} from "./sagas/courseSaga";
import {paymentTypesList} from "./sagas/paymentTypeSaga";
import {dashboardData} from "./sagas/dashboardSaga";
import {
    ADD_MEMBER_TO_CURRENT_SEASON,
    ADD_PAYMENT,
    CREATE_OR_UPDATE_PAYMENT_TYPE,
    DELETE_MEMBER_FROM_CURRENT_SEASON,
    DELETE_PAYMENT_TYPE,
    DELETE_USER,
    DISABLE_USER,
    ENABLE_USER,
    LOGOUT,
    MEMBER_EXISTS,
    SEND_CODE,
    UNDO_PAYMENT,
    UPDATE_FAMILY_PAYMENTS,
    UPDATE_USER,
    MEMBER_SEARCH,
    LOGIN,
    REFRESH_TOKEN,
    USER_BY_ACTIVATION_CODE,
    SET_PASSWORD,
    REGISTER_NEW_TENANT,
    END_VISIT,
    SAVE_VISIT_SUCCESS,
    LOAD_ANON_TABLES
} from "./actions";
import {usersList} from "./sagas/userSaga";
import {tablesList} from "./sagas/tableSaga";

const createUrl = (url) => {
    return window.location.host + url;
};

const createRestUrl = (url, ...replacements) => {
    if (replacements) {
        for (const idx in replacements) {
            const replacement = replacements[idx],
                regexp = new RegExp("\\{" + idx + "\\}", "g");

            url = url.replace(regexp, replacement);
        }
    }

    console.log("new REST-URL: [", url, "]");

    return '/rest/' + url;
};

const createMemberUrl = (id) => {
    return createRestUrl("/members/") + id;
};

const createSetCoursesUrl = (id) => {
    return createMemberUrl(id) + "/courses";
};

const routeCreator = (page, pars) => ({type: page, ...pars});

// Pagination URLs
const routesMap = {
    [LOGIN_PAGE]: '/login',
    [DASHBOARD]: '/dashboard',
    [MEMBERS_LIST]: '/members',
    [MEMBERS_DETAILS]: '/members/details/:id',
    [MEMBERS_CREATE]: '/members/create',
    [MEMBERS_FILTER]: createUrl('/members/filter/:filter'),
    [PAYMENT_TYPES_LIST]: '/paymentTypes',
    [COURSES_LIST]: '/courses',
    [COURSES_CREATE]: createUrl('/courses/create'),
    [USERS_LIST]: '/users',
    [FORGOT_PASSWORD_SUCCESS]: '/recoverPassword/success',
    [FORGOT_PASSWORD]: '/recoverPassword/:activationCode',
    [REGISTER]: '/register',
    [REGISTER_SUCCESS]: '/register/success',
    [TABLES]: '/tables',
    [VISIT]: '/visit/:tenantId',
    [SAVE_VISIT_SUCCESS]: '/visit/:tenantId/:visitId',
    [VISIT_LIST]: '/visits',
    [MESSAGES_LIST]: '/messages'
};

// REST Urls
const restMap = {
    [DASHBOARD]: (season) => createRestUrl('dashboard/' + (season ?  season : '')),
    [MEMBERS_LIST]: createRestUrl('members'),
    [MEMBERS_CREATE]: createRestUrl('members'),
    [MEMBERS_DELETE]: createRestUrl('members'),
    [ADD_PAYMENT]: (id, year, amount) => createRestUrl('members/{0}/payment/{1}/{2}', id, year, amount),
    [UNDO_PAYMENT]: (id, year) => createRestUrl('members/{0}/payment/{1}/undo', id, year),
    [MEMBERS_FOR_SEASON]: (season) => (createRestUrl('members/season/{0}', season)),
    [COURSES_LIST]: createRestUrl('courses'),
    [COURSES_LIST_OWNER]: createRestUrl('courses/owner'),
    [COURSES_LIST_OWNER_ID]: (businessKey) => createRestUrl(`courses/owner/${businessKey}`),
    [COURSES_CREATE]: createRestUrl('courses'),
    [SEASONS_LIST]: createRestUrl('seasons'),
    [SEASONS_ADD]: createRestUrl('seasons'),
    [PAYMENT_TYPES_LIST]: createRestUrl('paymentTypes'),
    [CREATE_OR_UPDATE_PAYMENT_TYPE]: (id) => (id === 0 || !!id ? createRestUrl('paymentTypes/{0}', id) : createRestUrl('paymentTypes')),
    [DELETE_PAYMENT_TYPE]: createRestUrl('paymentTypes'),
    [ADD_MEMBER_TO_CURRENT_SEASON]: (memberId) => (createRestUrl('members/{0}/currentSeason', memberId)),
    [DELETE_MEMBER_FROM_CURRENT_SEASON]: (memberId) => (createRestUrl('members/{0}/undoCurrentSeason', memberId)),
    [LOGOUT]: createRestUrl('user/logout'),
    [LOGIN]: createRestUrl('auth/login'),
    [REFRESH_TOKEN]: createRestUrl('auth/refresh'),
    [USERS_LIST]: createRestUrl('user'),
    [COACH_LIST]: createRestUrl('coach'),
    [DELETE_USER]: createRestUrl('user'),
    [DISABLE_USER]: (userId) => (createRestUrl('user/{0}/disable', userId)),
    [ENABLE_USER]: (userId) => (createRestUrl('user/{0}/enable', userId)),
    [SEND_CODE]: createRestUrl('register/sendCode'),
    [UPDATE_USER]: (userId) => (createRestUrl('coach/{0}', userId)),
    [MEMBER_EXISTS]: (firstname, lastname) => (createRestUrl('members/exists/{0}/{1}', firstname, lastname)),
    [UPDATE_FAMILY_PAYMENTS]: createRestUrl("members/updateFamilyPayments"),
    [MEMBER_SEARCH]: (search) => createRestUrl(`members/search?term=${search}`),
    [MEMBERS_DETAILS]: (id) => createRestUrl(`user/${id}`),
    [COURSE_CATEGORIES]: createRestUrl('courseCategory'),
    [USER_BY_ACTIVATION_CODE]: (activationCode) => createRestUrl(`register/findByActivationCode/${activationCode}`),
    [SET_PASSWORD]: createRestUrl('register'),
    [CURRENT_USER]: createRestUrl('user/currentUser'),
    [REGISTER_NEW_TENANT]: createRestUrl('register'),
    [TABLES]: (tenantId) => createRestUrl(`table/${tenantId}`),
    [LOAD_ANON_TABLES]: (tenantId) => createRestUrl(`table/anon/${tenantId}`),
    [VISIT]: (tenantId) => createRestUrl(`visit/${tenantId}`),
    [END_VISIT]: (visitId) => createRestUrl(`visit/end/${visitId}`),
    [VISIT_LIST]: createRestUrl('visit/tenantAdmin'),
    [MESSAGES_LIST]: createRestUrl('message/get')
};

// saga actions depending on URL (change)
const sagasMap = {
    [DASHBOARD]: dashboardData,
    [DASHBOARD2]: dashboardData,
    [MEMBERS_LIST]: membersList,
    [MEMBERS_DETAILS]: membersDetails,
    [MEMBERS_CREATE]: membersList,
    [MEMBERS_FILTER]: membersList,
    [COURSES_LIST]: coursesList,
    [COURSES_CREATE]: coursesCreate,
    [PAYMENT_TYPES_LIST]: paymentTypesList,
    [USERS_LIST]: usersList,
    [LOGIN_PAGE]: loginInit,
    [TABLES]: tablesList,
    [MESSAGES_LIST]: messagesList
};

export {createUrl, createRestUrl, createMemberUrl, createSetCoursesUrl, routeCreator, routesMap, sagasMap, restMap};