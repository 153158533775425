/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {customInputForm} from "../../customComponents";
import {object, string} from "yup";
import {connect} from "react-redux";
import {getActivationCodeCreator, registerCreator, showLogin} from "../../actionCreators/generalActionCreators";
import {error} from "react-notification-system-redux";

const RegisterSchema = object().shape({
    password: string().required('Passwort muss angegeben werden'),
    passwordRepeat: string().required('Passwort Wiederholung muss angegeben werden.').test('passwords-match',
        'Passwort und dessen Wiederholung müssen übereinstimmmen', function (value) {
            return this.parent.password === value;
        })
});

class ForgotPassword extends React.Component {

    componentDidMount() {
        const {getUserByActivationCode, activationCode, goToLogin, noActivationCodeMessage} = this.props;

        if (activationCode) {
            getUserByActivationCode(activationCode);
        } else {
            goToLogin();
            noActivationCodeMessage();
        }
    }

    render() {
        const {userToActivate, register, activationCode} = this.props;

        return userToActivate ? (
            <>
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-center text-muted">
                                <h1>Registrierung</h1>
                            </div>
                            {/*<div className="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
              <div className="text-center">
                <Button
                  className="btn-neutral btn-icon mr-4"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>*/}
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <small>Bitte vergeben Sie ein neues Passwort</small>
                            </div>
                            <Formik
                                initialValues={{
                                    username: userToActivate.get('username'),
                                    password: '',
                                    passwordRepeat: ''
                                }}
                                validationSchema={RegisterSchema}
                                onSubmit={(values, {setSubmitting, resetForm}) => {
                                    console.log('values to save', activationCode, values.password, values.passwordRepeat);
                                    setSubmitting(true);
                                    register(activationCode, values.password, values.passwordRepeat, setSubmitting);
                                }}>
                                {({isSubmitting}) => (
                                    <Form>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className='disabled'>
                                                        <i className="ni ni-hat-3"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Field id='username' placeholder="Benutzername..." type="text"
                                                       name="username"
                                                       component={customInputForm}
                                                       disabled={true}/>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Field placeholder="Passwort" type="password" id='password' name='password'
                                                       component={customInputForm}/>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Field placeholder="Passwort wiederholen" type="password"
                                                       id='passwordRepeat' name='passwordRepeat'
                                                       component={customInputForm}/>
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="text-muted font-italic">
                                            <small>
                                                password strength:{" "}
                                                <span className="text-success font-weight-700">strong</span>
                                            </small>
                                        </div>
                                        {/*<Row className="my-4">
                                            <Col xs="12">
                                                <div
                                                    className="custom-control custom-control-alternative custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id="customCheckRegister"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="customCheckRegister"
                                                    >
                                                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>*
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>*/}
                                        <div className="text-center">
                                            <Button className="mt-4" color="primary" type="submit" disabled={isSubmitting}>
                                                Passwort ändern
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </>
        ) : '';
    }
}

export default connect((state) => ({
    activationCode: state.location.payload.activationCode,
    userToActivate: state.sua.get('userToActivate')
}), (dispatch) => ({
    gotoLogin: () => dispatch(showLogin()),
    register: (activationCode, password, passwordRepeat, setSubmitting) => dispatch(registerCreator(activationCode, password, passwordRepeat, setSubmitting)),
    getUserByActivationCode: (activationCode) => dispatch(getActivationCodeCreator(activationCode)),
    noActivationCodeMessage: () => error({
        title: 'Es wurde kein Aktivierungscode angegeben',
        message: 'Da kein gültiger Aktivierungscode bereitgestellt wurde, wurden Sie auf die Loginseite umgeleitet'
    })
}))(ForgotPassword);
