import React from "react";
import {Form, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DELETE_TABLE, DELETE_USER} from "../../actions";
import {connect} from "react-redux";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class DeleteTableModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {table, isOpen, hideModal, backdrop, deleteTable, user} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Löschen des Tisches {table ? table.get('name') : ''}</ModalHeader>
            <ModalBody>
                Sind Sie sicher dass Sie den Tisch <strong>{table ? table.get('name') : ''}</strong> löschen möchten?
                Dieser Tisch geht somit unwiderruflich verloren.
            </ModalBody>
            <ModalFooter>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    deleteTable(table, user)
                }}>
                    <Button type="button" color="secondary" onClick={hideModal}>Abbrechen</Button>
                    <Button type="submit" className="primary">Löschen</Button>
                </Form>
            </ModalFooter>
        </Modal>
    }
}

DeleteTableModal = connect((state, ownProps) => ({
    table: state.modal.getIn([ownProps.modalName, 'table']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop']),
    user: state.sua.get('user')
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    deleteTable: (table, user) => (dispatch({
        type: DELETE_TABLE,
        table: table,
        user: user,
        modalName: ownProps.modalName
    }))
}))(DeleteTableModal);

export default DeleteTableModal;