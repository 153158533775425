import {
    ADD_MEMBER_TO_CURRENT_SEASON_SUCCESS,
    ADDED_SEASON,
    APP_LOADED,
    CHANGE_SEASON,
    CREATE_COURSE_CATEGORY_SUCCESS,
    CREATED_COURSE_SUCCESS,
    CREATED_PAYMENT_TYPE_SUCCESS,
    DASHBOARD_DATA_COMPLETE,
    DELETE_PAYMENT_TYPE_SUCCESS,
    DELETED_COURSE,
    DISABLED_USER,
    EDIT_PAYMENT_TYPE_SUCCESS,
    ENABLED_USER,
    FETCH_MEMBER_DETAIL_COMPLETE, FETCH_MESSAGS_SUCCESS,
    LIST_COURSES_COMPLETE,
    LIST_MEMBERS_COMPLETE,
    LIST_PAYMENT_TYPES_COMPLETE, LIST_TABLES_COMPLETE,
    LIST_USERS_COMPLETE,
    LOGIN_SUCCESS, LOGOUT,
    SHOW_MODAL,
    UNDONE_PAYMENT,
    UPDATE_USER_SUCCESS,
    UPDATED_MEMBER, USER_BY_ACTIVATION_CODE_SUCCESS,
    USER_DELETED, VISIT_ENDED
} from "../actions";
import {ALL_MEMBERS, MEMBERS_OF_CURRENT_SEASON} from "../types";
import Util from "../api/Util";
import {fromJS, List, Map} from 'immutable';
import {FETCH_DATA_ERROR} from "./tableReducer";
import {initialSaga} from "../saga";
import {FETCH_COURSES_FOR_COACH_SUCCESS} from "../api/apiCreators";

const initialState = Map({
        memberTableFilter: ALL_MEMBERS,
        members: List(),
        filteredMembers: List(),
        courses: List(),
        basename: '',
        seasons: List(),
        paymentTypes: List(),
        users: List(),
        user: null,
        jwtRefreshToken: localStorage.getItem('jwtRefreshToken'),
        jwtAccessToken: localStorage.getItem('jwtAccessToken'),
        currencyFormatter: new Intl.NumberFormat('de-AT', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}),
        dateFormatter: new Intl.DateTimeFormat('de-AT', {
            month: "2-digit",
            day: "2-digit",
            year: "numeric"
        })
    }),
    actionMap = {
        [LIST_MEMBERS_COMPLETE]: listMembersComplete,
        [LIST_COURSES_COMPLETE]: listCoursesComplete,
        [UPDATED_MEMBER]: memberUpdated,
        [SHOW_MODAL]: showModal,
        [APP_LOADED]: appLoaded,
        [MEMBERS_OF_CURRENT_SEASON]: showMembersOfCurrentSeason,
        [ALL_MEMBERS]: showAllMembers,
        [ADDED_SEASON]: seasonAdded,
        [ADD_MEMBER_TO_CURRENT_SEASON_SUCCESS]: memberToCurrentSeasonSuccess,
        [LIST_PAYMENT_TYPES_COMPLETE]: listPaymentTypesComplete,
        [EDIT_PAYMENT_TYPE_SUCCESS]: editPaymentTypeSuccess,
        [DELETE_PAYMENT_TYPE_SUCCESS]: deletePaymentTypeSuccess,
        [UNDONE_PAYMENT]: undonePaymentSuccess,
        [CHANGE_SEASON]: changeCurrentSeason,
        [CREATED_COURSE_SUCCESS]: createdCourse,
        [DELETED_COURSE]: deletedCourse,
        [DASHBOARD_DATA_COMPLETE]: dashboardDataComplete,
        [LIST_USERS_COMPLETE]: listUsersComplete,
        [DISABLED_USER]: disabledUser,
        [ENABLED_USER]: enabledUser,
        [UPDATE_USER_SUCCESS]: updatedUser,
        [LOGIN_SUCCESS]: loginSuccess,
        [FETCH_MEMBER_DETAIL_COMPLETE]: fetchMemberDetailComplete,
        [CREATED_PAYMENT_TYPE_SUCCESS]: createPaymentTypeSuccess,
        [CREATE_COURSE_CATEGORY_SUCCESS]: courseCategoryCreateSucces,
        [LOGOUT]: logout,
        [USER_BY_ACTIVATION_CODE_SUCCESS]: getUserByActivationCodeSuccess,
        [FETCH_COURSES_FOR_COACH_SUCCESS]: fetchCoursesForCoachSuccess,
        [LIST_TABLES_COMPLETE]: listTablesComplete,
        [VISIT_ENDED]: visitEnded,
        [FETCH_MESSAGS_SUCCESS]: fetchMessagesSuccess
    };

function fetchMessagesSuccess(state, action) {
    return state.set('messages', fromJS(action.messages));

}

function visitEnded(state) {
    return state.set('visitEnded', true);
}

function listTablesComplete(state, action) {
    return state.set('tables', fromJS(action.tables));
}

function fetchCoursesForCoachSuccess(state, action) {
    return state.setIn(['coursesForCoach'], fromJS(action.courses));
}

function getUserByActivationCodeSuccess(state, action) {
    return state.set('userToActivate', fromJS(action.user));
}

function logout(state, action) {
    localStorage.removeItem('jwtAccessToken');
    localStorage.removeItem('jwtRefreshToken');

    return state.delete('jwtAccessToken').delete('jwtRefreshToken');
}

function courseCategoryCreateSucces(state, action) {
    return state.set('courseCategories', fromJS(action.courseCategories));
}

function createPaymentTypeSuccess(state, action) {
    return state.set('paymentTypes', fromJS(action.paymentTypes));
}

function fetchMemberDetailComplete(state, action) {
    return state.set('memberEdit', fromJS(action.member)).set('paymentTypes', fromJS(action.paymentTypes)).set('courses', fromJS(action.courses));
}

function updatedUser(state, action) {
    return state.set("users", fromJS(action.users));
}

function disabledUser(state, action) {
    return state.setIn(["users", action.userId.toString(), "disabled"], true);
}

function enabledUser(state, action) {
    return state.setIn(["users", action.userId.toString(), "disabled"], false);
}

function listUsersComplete(state, action) {
    return state.merge({
        users: action.users
    });
}

function dashboardDataComplete(state, action) {
    console.log("DASHBOARD DATA COMPLETE CALLED");
    return state.merge({
        dashboardData: fromJS(action.dashboardData)
    });
}

function deletedCourse(state, action) {
    return state.set("courses", fromJS(action.courses));
}

function createdCourse(state, action) {
    return state.set('courses', fromJS(action.courses));
}

function changeCurrentSeason(state, action) {
    const newSeason = state.get("seasons").find(function (val) {
        return val.get("season") === parseInt(action.season, 10);
    });

    return state.set("activeSeason", fromJS(newSeason));
}

function undonePaymentSuccess(state, action) {
    console.log("CALLED UNDONE_PAYMENT SUCCESS", state, action);

    const newState = state.deleteIn(["members", action.memberId.toString(), "payments", action.year.toString()]);

    return newState.merge({
        filteredMembers: Util.filterMembers(newState.get("members"), newState.get("memberTableFilter"), newState.get("activeSeason"))
    });
}

function seasonAdded(state, action) {
    const newMembers = state.get("members").map(member =>
            member.update("activeInCurrentSeason", par => false).update("paidInCurrentSeason", par => false)
        ),
        filteredMembers = Util.filterMembers(newMembers, state.get("memberTableFilter"), action.newSeason),
        newSeason = fromJS(action.newSeason);

    return state.merge({
        members: newMembers,
        filteredMembers: filteredMembers,
        activeSeason: newSeason,
        seasons: state.get("seasons").push(newSeason)
    });
}

function deletePaymentTypeSuccess(state, action) {
    return state.set("paymentTypes", fromJS(action.paymentTypes));
}

function editPaymentTypeSuccess(state, action) {
    console.log("PAYMENT TYPE SUCCESS", state, action, action.paymentType.businessKey, state.getIn(["paymentTypes", action.paymentType.businessKey.toString()]));
    return state.updateIn(["paymentTypes", action.paymentType.businessKey.toString()], paymentType => paymentType.merge(fromJS(action.paymentType)));
}

// TODO: save into member object!!

function memberToCurrentSeasonSuccess(state, action) {
    console.log("STATE", state);

    let members = state.get("members"),
        filteredMembers = state.get("filteredMembers");
    const memberId = action.memberId.toString();


    console.log("members, filteredMembers", members, filteredMembers);
    console.log(members.getIn([memberId, "activeSeasons"]));

    if (members.getIn([memberId, "activeSeasons"])) {
        members = members.updateIn([memberId, "activeSeasons"], activeSeasons => activeSeasons.push(state.getIn(["activeSeason", "season"])));
    } else {
        members = members.setIn([memberId, "activeSeasons"], List([state.getIn(["activeSeason", "season"])]));
    }

    members = members.setIn([memberId, "activeInCurrentSeason"], true);

    const newFilteredMembers = Util.filterMembers(members, state.get("memberTableFilter"), state.get("activeSeason"));

    return state.merge(Map({
        members: members,
        filteredMembers: newFilteredMembers
    }));
}

function showAllMembers(state, action) {
    return state.merge({
        filteredMembers: state.get("members").toList(),
        memberTableFilter: ALL_MEMBERS
    });
}

function showMembersOfCurrentSeason(state, action) {
    return state.merge({
        filteredMembers: Util.filterMembers(state.get("members"), MEMBERS_OF_CURRENT_SEASON, state.get("activeSeason")),
        memberTableFilter: MEMBERS_OF_CURRENT_SEASON
    });
}

function appLoaded(state, action) {
    return state.merge(Map({
        //seasons: fromJS(action.seasons),
        user: fromJS(action.user)
        //activeSeason: fromJS(action.seasons.reduce((acc, curr) => curr.season > acc.season ? curr : acc))
    }));
}


function showModal(state, action) {
    return state.merge(Map({
        modalProps: action.modalProps
    }));
}

function memberUpdated(state, action) {
    console.log("MEMBER_UPDATED called");

    const newState = state.mergeIn(["members", action.id.toString()], action.member);

    return newState.merge({
        filteredMembers: Util.filterMembers(newState.get("members"), newState.get("memberTableFilter"), newState.get("activeSeason"))
    });
}

function listPaymentTypesComplete(state, action) {
    return state.merge(Map({
        paymentTypes: fromJS(action.paymentTypes)
    }));
}

function listCoursesComplete(state, action) {
    return state.set('courses', fromJS(action.courses)).set('coaches', fromJS(action.coaches)).set('courseCategories', fromJS(action.courseCategories));
}

function listMembersComplete(state, action) {
    return state.merge(Map({
        courses: fromJS(action.courses),
        paymentTypes: fromJS(action.paymentTypes)
    }));
}

function loginSuccess(state, action) {
    localStorage.setItem('jwtAccessToken', action.loginData.jwtAccessToken);
    localStorage.setItem('jwtRefreshToken', action.loginData.jwtRefreshToken);
    localStorage.setItem('user', fromJS(action.loginData.otherDto));

    return state.merge(Map({
        user: fromJS(action.loginData.otherDto),
        jwtAccessToken: action.loginData.jwtAccessToken,
        jwtRefreshToken: action.loginData.jwtRefreshToken,
    }));
}

function suaReducer(state = initialState, action) {
    console.log("test reducer called", state, action);

    if (actionMap[action.type] && typeof actionMap[action.type] === "function") {
        return actionMap[action.type](state, action);
    }

    return state;
}

export default suaReducer;