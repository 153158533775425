/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {customInputForm, customReactSelect} from "../../customComponents";
import {object, string} from "yup";
import {connect} from "react-redux";
import {loadAnonTablesCreator, saveVisitCreator,} from "../../actionCreators/generalActionCreators";


const VisitSchema = object().shape({
    firstname: string().required('Pflichtfeld'),
    lastname: string().required('Pflichtfeld'),
    beginOfVisit: string().required('Pflichtfeld'),
    phone: string().test('oneOfRequired', 'Bitte geben Sie eines der beiden Felder ein: Entweder Telefonnummer oder E-Mail-Adresse.',
        function (item) {
            return this.parent.email || item;
        }
    ),
    table: string().required('Pflichtfeld'),
    email: string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
});

class Visit extends React.Component {

    componentDidMount() {
        const {tenantId} = this.props;

        if (tenantId) {
            const {loadTableOptions} = this.props;

            loadTableOptions(tenantId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tenantId} = this.props;
        const prevTenantId = prevProps.tenantId;

        if (tenantId !== prevTenantId) {
            const {loadTableOptions} = this.props;
            loadTableOptions(tenantId);
        }
    }

    getCurrentTime() {
        const now = new Date();
        const hours = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
        const minutes = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();

        return hours + ":" + minutes;
    }

    getTableOptions() {
        const {tables} = this.props;

        return tables ? tables.map(table => ({
            label: table.get('name'),
            value: table.get('name')
        })) : [];
    }

    render() {
        const {saveVisit, tenantId, table, tables} = this.props;

        const userDataString = localStorage.getItem("userData");

        let userData = null;
        if (userDataString) {
            userData = JSON.parse(userDataString);
        }

        return <>
            <Col lg="6" md="8">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div className="text-center text-muted">
                            <h1>Kontakterfassung zur Nachverfolgung bei Covid-19-(Verdachts-)Fällen</h1>
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Bitte geben Sie Ihre Daten ein</small>
                        </div>
                        <Formik
                            initialValues={{
                                firstname: userData && userData.firstname ? userData.firstname : '',
                                lastname: userData && userData.lastname ? userData.lastname : '',
                                beginOfVisit: `${new Date().toLocaleDateString('en-CA')}T${this.getCurrentTime()}`,
                                table: table ? ({
                                    value: table,
                                    label: table
                                }) : '',
                                /*zipCode: userData && userData.zipCode ? userData.zipCode : '',
                                street: userData && userData.street ? userData.street : '',
                                city: userData && userData.city ? userData.city : '',
                                country: userData && userData.country ? userData.country : 'Österreich',*/
                                phone: userData && userData.phone ? userData.phone : '',
                                email: userData && userData.email ? userData.email : '',
                                saveData: userData && (userData.saveData === false || userData.saveData === true) ? userData.saveData : false,
                            }}
                            validationSchema={VisitSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(true);

                                if (values.saveData === true) {
                                    localStorage.setItem("userData", JSON.stringify(values));
                                }

                                values.table = values.table && values.table.value ? values.table.value : values.table;
                                try {
                                    values.beginOfVisit = new Date(values.beginOfVisit).getTime();
                                } catch (ex) {

                                }

                                saveVisit(values, tenantId, setSubmitting);
                            }}>
                            {({isSubmitting}) => (
                                <Form>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='firstname' placeholder="Vorname..." type="text"
                                                   name="firstname"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='lastname' placeholder="Nachname..." type="text"
                                                   name="lastname"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <Field id='table' placeholder="Tischnummer..." type="text"
                                                   name="table"
                                                   style={{width: '100%'}}
                                                   component={customReactSelect}
                                                   options={this.getTableOptions()}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-calendar-alt"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='beginOfVisit' placeholder="Datum..." type="datetime-local"
                                                   name="beginOfVisit"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    {/*<FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-building"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='street' placeholder="Adresse..." type="text"
                                                   name="street"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-building"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='zipCode' placeholder="Postleitzahl..." type="text"
                                                   name="zipCode"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-building"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='city' placeholder="Ort..." type="text"
                                                   name="city"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-building"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='country' placeholder="Land..." type="text"
                                                   name="country"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>*/}
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-phone-alt"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='phone' placeholder="Telefonnummer..." type="phone"
                                                   name="phone"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field id='email' placeholder="E-Mail..." type="email"
                                                   name="email"
                                                   component={customInputForm}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="ml-4">
                                        <InputGroup className="input-group-alternative mb-3">
                                            <Field id='saveData' placeholder="E-Mail..." type="checkbox"
                                                   name="saveData"
                                                   component={customInputForm}/> Daten für zukünftige Besuche speichern
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button className="mt-4" color="primary" type="submit" disabled={isSubmitting}>
                                            Speichern
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="mt-5 text-sm">
                            Sie haben unseren Gastronomiebetrieb besucht und uns Ihre Daten (Vor- und Zuname,
                            E-Mail-Adresse, Telefonnummer) auf Basis der aktuellen gesetzlichen
                            Grundlage zur Verfügung gestellt. Wir verarbeiten diese Daten ausschließlich zur raschen
                            Kontaktpersonennachverfolgung im Falle eines COVID-19-Verdachts.
                            Die Verarbeitung Ihrer vorgenannten personenbezogenen Daten erfolgt entsprechend Art 6 Abs 1
                            lit c und lit d DSGVO auf Grund der uns treffenden gesetzlichen
                            Verpflichtung gem. § 1 Z 1 lit e der Verordnung des Magistrats der Stadt Wien betreffend
                            Auskunftserteilung für Contact Tracing im Zusammenhang mit Verdachtsfällen von COVID-19 iZm
                            § 5 Epidemiegesetz.
                            <div className="mt-2">
                                <span className="font-weight-bold">Speicherdauer/Löschungsfrist:</span> Wir löschen Ihre
                                Daten nach 28 Tagen.
                                Wir geben Ihre Daten ausschließlich an folgende Empfänger bzw. Empfängerkategorien
                                weiter:
                                n Gesundheitsbehörden gemäß § 5 Abs. 3 Epidemiegesetz und § 10 Abs. 2 DSG
                                (Rechtsgrundlagen
                                im Sinne des Art 9 Abs 2 lit i DSGVO) auf Verlangen.
                            </div>
                            <div className="mt-2">
                                <span className="font-weight-bold">Rechtsbehelfsbelehrung:</span> Ihnen stehen
                                grundsätzlich die Rechte auf Auskunft,
                                Berichtigung,
                                Löschung, Einschränkung, Datenübertragbarkeit und
                                Widerspruch zu. Dafür wenden Sie sich an uns (Kontaktdaten siehe unten). Wenn Sie
                                glauben,
                                dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht
                                verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden
                                sind, können Sie sich bei der Aufsichtsbehörde beschweren.
                                In Österreich ist die Datenschutzbehörde zuständig.
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    }
}

export default connect((state) => ({
    tenantId: state.location.payload.tenantId,
    table: state.location.query && state.location.query.table ? state.location.query.table : null,
    tables: state.sua.get('tables')
}), (dispatch) => ({
    saveVisit: (data, tenantId, setSubmitting) => dispatch(saveVisitCreator(data, tenantId, setSubmitting)),
    loadTableOptions: (tenantId) => dispatch(loadAnonTablesCreator(tenantId))
}))(Visit);
