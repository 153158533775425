/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// reactstrap components
import {Card, CardHeader, Col, Container, Row, Table} from "reactstrap";
// core components
import {chartOptions, parseOptions} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import {connect} from 'react-redux';
import {fetchDashboardDataCreator} from "../../api/apiCreators";
import {showModalCreator} from "../../reducers/modalReducer";
import QRCode from "qrcode.react";
import CardBody from "reactstrap/es/CardBody";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNav: 1,
            chartExample1Data: "data1"
        };
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }

        this.showDashboardUnassignedMembersModalName = 'showDashboardUnassignedMembersModalName';
        this.unassignedMembersTableName = 'unassignedMembersTableName';
    }

    toggleNavs = (e, index) => {
        e.preventDefault();
        this.setState({
            activeNav: index,
            chartExample1Data:
                this.state.chartExample1Data === "data1" ? "data2" : "data1"
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevSeason = prevProps.activeSeason && prevProps.activeSeason.get('season') ? prevProps.activeSeason.get('season') : null;
        const newSeason = this.props.activeSeason && this.props.activeSeason.get('season') ? this.props.activeSeason.get('season') : null;

        if (prevSeason !== newSeason) {
            this.props.fetchDashboardData(newSeason);
        }
    }

    getQrCodeText() {
        const {user, basename} = this.props;

        let baseUrl = '';
        if (basename && basename !== '') {
            const basenameIdx = window.location.href.indexOf(basename);
            baseUrl = window.location.href.substring(0, basenameIdx + basename.length);
        } else {
            baseUrl = window.location.origin;
        }

        return encodeURI(`${baseUrl}/visit/${user ? user.get('businessKey') : ''}`);
    }

    render() {
        const {dashboardData, showDashboardUnassignedMembersModal, activeSeason} = this.props;

        return (
            <>
                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="mb-5 mb-xl-0" xl="8">
                            <Card className="shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase  ls-1 mb-1">
                                                Ihr persönlicher QR-Code für Ihren Betrieb
                                            </h6>
                                        </div>

                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="col">
                                        <QRCode value={this.getQrCodeText()}/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/*<Row>
                        <Col className="mb-5 mb-xl-0" xl="8">
                            <Card className="bg-gradient-default shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                Overview
                                            </h6>
                                            <h2 className="text-white mb-0">Sales value</h2>
                                        </div>
                                        <div className="col">
                                            <Nav className="justify-content-end" pills>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames("py-2 px-3", {
                                                            active: this.state.activeNav === 1
                                                        })}
                                                        href="#pablo"
                                                        onClick={e => this.toggleNavs(e, 1)}
                                                    >
                                                        <span className="d-none d-md-block">Month</span>
                                                        <span className="d-md-none">M</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames("py-2 px-3", {
                                                            active: this.state.activeNav === 2
                                                        })}
                                                        data-toggle="tab"
                                                        href="#pablo"
                                                        onClick={e => this.toggleNavs(e, 2)}
                                                    >
                                                        <span className="d-none d-md-block">Week</span>
                                                        <span className="d-md-none">W</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>*/}
                    {/* Chart */}
                    {/*<div className="chart">
                                        <Line
                                            data={chartExample1[this.state.chartExample1Data]}
                                            options={chartExample1.options}
                                            getDatasetAtEvent={e => console.log(e)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card className="shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                Performance
                                            </h6>
                                            <h2 className="mb-0">Total orders</h2>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>*/}
                    {/* Chart */}
                    {/*<div className="chart">
                                        <Bar
                                            data={chartExample2.data}
                                            options={chartExample2.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>*/}
                </Container>
            </>
        );
    }
}

export default connect(state => ({
    activeSeason: state.sua.get('activeSeason'),
    dashboardData: state.sua.get('dashboardData'),
    user: state.sua.get('user'),
    basename: state.sua.get('basename')
}), dispatch => ({
    fetchDashboardData: (season) => dispatch(fetchDashboardDataCreator(season)),
    showDashboardUnassignedMembersModal: (modalName, unassignedMembers) => dispatch(showModalCreator(modalName, {unassignedMembers: unassignedMembers}))
}))(Dashboard);
