import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class AboutModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {isOpen, hideModal, backdrop} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Über diese Applikation</ModalHeader>
            <ModalBody>
                <h3>Über diese Applikation</h3>

                Diese Applikation dient der Erfassung von Corona-(Verdachts-)Fällen und erleichtert den Kunden die
                Erfassung Ihrer Daten beim Betreten der Einrichtung bzw. des jeweiligen Geschäfts. Die Daten dienen
                ausschließlich zur Nachverfolgung von Corona-(Verdachts-)Fällen und werden nach 28 Tagen automatisch
                vom System gelöscht.

                <h3 className="mt-3">Verwendete Lizenzen</h3>
                Das zugrunde liegende <a href="https://www.creative-tim.com/product/argon-design-system" target="_blank">Theme</a>,
                das hier verwendet wird, wurde unter der MIT-License veröffentlich und
                kann daher sowohl für private als auch kommerzielle Zwecke verwendet werden. Es wurden auch Icons verwendet,
                die sowohl privat als kommerziell genutzt werden können. Diese müssen aber attributiert werden. Die
                Quellen sind wie folgt angeführt:
                <ul>
                    <li><a href="https://www.flaticon.com/free-icon/coronavirus_2750826?term=coronavirus&page=1&position=9"
                           target="_blank">https://icon-icons.com/</a></li>
                    <li><a href="https://icon-icons.com/de/symbol/covid-virus-coronavirus-Infektion-Krankheit-Grippe-corona/140898"
                           target="_blank">https://www.flaticon.com/</a></li>
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" onClick={hideModal}>Schließen</Button>
            </ModalFooter>
        </Modal>
    }
}

AboutModal = connect((state, ownProps) => ({
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
}))(AboutModal);

export default AboutModal;